import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql, Link } from "gatsby";
import { MenuIcon, ChevronRightIcon } from "@heroicons/react/outline";

const Header = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
					}
				}
			}
		`
	);
	const info = [
		{
			id: 0,
			title: "HOME",
			link: "/",
		},
		{
			id: 1,
			title: "お知らせ",
			link: "/#news",
		},
		{
			id: 2,
			title: "ご利用例",
			link: "/#useSample",
		},
		{
			id: 3,
			title: "ご利用料金",
			link: "/#price",
		},
		{
			id: 4,
			title: "ご利用時の流れ",
			link: "/#flow",
		},
		{
			id: 5,
			title: "よくある質問",
			link: "/#faq",
		},
		{
			id: 6,
			title: "お問い合わせ",
			link: "/#contact",
		},
		{
			id: 7,
			title: "E&Be ギャラリー",
			link: "/gallery/",
		},
	];
	const Button = (props) => {
		return (
			<>
				<input className="hidden" id="nav-checkbox" type="checkbox" />
				<label className="text-center" htmlFor="nav-checkbox">
					<span className="block text-theme cursor-pointer absolute top-8 right-4">
						{props.children}
					</span>
				</label>
			</>
		);
	};
	const ActiveBackground = () => {
		return (
			<label
				className="hidden z-40 fixed top-0 right-0 w-full h-full bg-black opacity-0 duration-300 nav-bg"
				htmlFor="nav-checkbox"
			>
				<span></span>
			</label>
		);
	};
	return (
		<header className="w-full bg-white relative">
			<div className="max-w-6xl mx-auto text-center pt-6">
				<Link to="/">
					<StaticImage
						src="../images/E_be-living.png"
						alt={data.site.siteMetadata.title}
						className="inline-block"
						width={64}
						height={60}
						layout="constrained"
					/>
				</Link>
				<div className="flex items-center h-full">
					<div>
						<Button>
							<MenuIcon className="inline-block w-7 h-7" />
						</Button>
						{/* 🍔 */}
						<ActiveBackground />

						<div className="fixed overflow-auto top-0 right-0 z-50 h-full shadow-2xl duration-300 text-left nav-content bg-white">
							<nav>
								<ul className="">
									{info.map((i) => {
										return (
											<li
												key={i.id}
												className="w-full border-b bg-gray-50 lg:hover:bg-gray-200 lg:duration-300"
											>
												<Link
													className="flex items-center justify-between p-4"
													to={i.link}
												>
													<p>{i.title}</p>
													<ChevronRightIcon className="inline-block w-4 h-4 text-accent" />
												</Link>
											</li>
										);
									})}
								</ul>
							</nav>
						</div>
					</div>
				</div>
			</div>
		</header>
	);
};

export default Header;
