import React from "react";
import Layout from "../components/Layout";
import { graphql, Link } from "gatsby";

const Posts = ({ data }) => {
	const Breadcrumb = () => {
		return (
			<nav className="max-w-3xl mx-auto">
				<ol
					itemScope
					itemType="https://schema.org/BreadcrumbList"
					className="flex items-center gap-1 w-full text-sm whitespace-nowrap overflow-x-scroll"
				>
					<li
						itemProp="itemListElement"
						itemScope
						itemType="https://schema.org/ListItem"
					>
						<Link
							itemProp="item"
							to="/"
							className="flex items-center gap-1 hover:underline"
						>
							<span itemProp="name">HOME</span>/
						</Link>
						<meta itemProp="position" content="1" />
					</li>
					<li
						itemProp="itemListElement"
						itemScope
						itemType="https://schema.org/ListItem"
					>
						<Link
							itemProp="item"
							to="/#news"
							className="flex items-center gap-1 hover:underline"
						>
							<span itemProp="name">お知らせ</span>/
						</Link>
						<meta itemProp="position" content="2" />
					</li>
					<li
						itemProp="itemListElement"
						itemScope
						itemType="https://schema.org/ListItem"
					>
						<Link
							itemProp="item"
							to={`/news${data.markdownRemark.fields.slug}`}
						>
							<span itemProp="name">
								{data.markdownRemark.frontmatter.title}
							</span>
						</Link>
						<meta itemProp="position" content="3" />
					</li>
				</ol>
			</nav>
		);
	};

	return (
		<Layout>
			<Breadcrumb />
			<article className="fast-fadein-animation font-sans max-w-3xl mx-auto">
				<div>
					<h1 className="font-bold text-xl tracking-wide fast-fadein-animation relative my-10 xl:text-2xl">
						{data.markdownRemark.frontmatter.title}
					</h1>
					<time
						className="block text-right text-accent"
						dateTime={data.markdownRemark.frontmatter.createdAt}
					>
						{data.markdownRemark.frontmatter.createdAt}
					</time>
				</div>
				<div
					className="prose py-10 max-w-none"
					dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
				/>
			</article>
		</Layout>
	);
};
export default Posts;

export const query = graphql`
	query PostsQuery($slug: String!) {
		markdownRemark(fields: { slug: { eq: $slug } }) {
			html
			fields {
				slug
			}
			frontmatter {
				title
				description
				createdAt(formatString: "YYYY.MM.DD")
			}
		}
		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
