import React from "react";

import Header from "./Header";
import Footer from "./Footer";

const Layout = ({ children }) => {
	return (
		<div className="font-mono text-theme opacity-animation overflow-x-hidden">
			<Header />
			<main>
				<div className="px-4 py-6">
					<div className="container mx-auto max-w-6xl">{children}</div>
				</div>
			</main>
			<Footer />
		</div>
	);
};

export default Layout;
