import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const Footer = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
					}
				}
			}
		`
	);
	const info = [
		{
			id: 0,
			title: "HOME",
			link: "/",
		},
		{
			id: 1,
			title: "お知らせ",
			link: "/#news",
		},
		{
			id: 2,
			title: "ご利用例",
			link: "/#useSample",
		},
		{
			id: 3,
			title: "ご利用料金",
			link: "/#price",
		},
		{
			id: 4,
			title: "ご利用時の流れ",
			link: "/#flow",
		},
		{
			id: 5,
			title: "よくある質問",
			link: "/#faq",
		},
		{
			id: 6,
			title: "お問い合わせ",
			link: "/#contact",
		},
		{
			id: 7,
			title: "E&Be ギャラリー",
			link: "/gallery/",
		},
	];
	const social = [
		{
			id: 1,
			title: "Twitter",
			link: "/",
		},
		{
			id: 2,
			title: "Facebook",
			link: "/",
		},
		{
			id: 3,
			title: "Instagram",
			link: "/",
		},
	];
	const Fnav = (props) => {
		return (
			<div className="w-1/2 px-4 xl:pl-0">
				<p className="font-bold tracking-widest mb-4">
					{data.site.siteMetadata.title} {props.title}
				</p>
				<nav className="">{props.children}</nav>
			</div>
		);
	};
	return (
		<>
			<footer className="bg-theme text-white">
				<div className="max-w-6xl py-10 mx-auto flex flex-col lg:flex-row xl:py-20 xl:px-0">
					<div className="ml-4 xl:ml-0 lg:w-80">
						<StaticImage
							src="../images/E_be-living.png"
							alt={data.site.siteMetadata.title}
							className="inline-block"
							placeholder="tracedSVG"
							width={64}
							height={60}
							layout="constrained"
						/>
					</div>
					<div className="flex mt-10 lg:mt-0">
						<Fnav title="INFO">
							<ul>
								{info.map((i) => {
									return (
										<li key={i.id} className="md:inline-block md:w-1/2">
											<Link
												className="p-1 inline-block w-full md:w-auto"
												to={i.link}
											>
												{i.title}
											</Link>
										</li>
									);
								})}
							</ul>
						</Fnav>
						<Fnav title="SOCIAL">
							<ul>
								{social.map((s) => {
									return (
										<li key={s.id}>
											<Link className="p-1 inline-block w-full" to={s.link}>
												{s.title}
											</Link>
										</li>
									);
								})}
							</ul>
						</Fnav>
					</div>
				</div>
				<div className="bg-white">
					<div className="max-w-7xl mx-auto p-4">
						<p className="text-sm text-center text-theme">
							&copy; {new Date().getFullYear()} {data.site.siteMetadata.title}{" "}
							All rights reserved.
						</p>
					</div>
				</div>
			</footer>
		</>
	);
};

export default Footer;
